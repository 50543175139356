import {NavLink, useNavigate } from "react-router-dom";
import classes from "./MainHeader.module.css";
import { useState } from "react";
import ErrorModal from "../UI/ErrorModal";
import {useDispatch, useSelector} from "react-redux";
import { authentificationActions } from "../../store/slices/authentification-slice";
import { ficheUserActions } from "../../store/slices/ficheUser-slice";
import LogoutIcon from '@mui/icons-material/Logout';
import Search from "../Feed/Searching/Search";
  

const MainHeader = () => {
 
  const authentification = useSelector((state) => state.authentification.dataResponse);

  
  const navigate = useNavigate();
     const isLoggedIn = useSelector((state) => state.authentification.isLoggedIn);

     const newFiche = useSelector((state) => state.ficheUser.ficheUserData.newFiche);

      const dispatch = useDispatch();


     const [error, setError] = useState(false);


     
    //activer ou désactiver le lien Accueil(flux)
    const conditionLinkActive = newFiche;


    
    
//pour se déconnecter
const logoutHandler = () => {
    dispatch(authentificationActions.logout());
    dispatch(ficheUserActions.logout());
};

const navigateToAccount = () => {
   navigate(`account/${authentification.userId}`);
};


    //gestion de la modale d'erreur
    const errorModalHandler = () => {
        setError({
          title:"Attention",
          message: "Vous devez finir de remplir le formulaire pour pouvoir quitter la page",
        });
    };                           


    return (
       <div className={classes.fixed}>
        <header className={classes.header}>
            { <NavLink
                  className={({isActive}) => (isActive ? classes.active : "")}
                  to="/"> 
            <h1>Graffiti</h1>  
            </NavLink>            
              
                }
          
           <nav>
             <ul>
                  {/**LA BARRE DE RECHERCHE* */}
                   {isLoggedIn && conditionLinkActive &&
                     <div className={classes.searchBar}>
                        <Search />
                    </div>}
                  
               {/**lien Poster lorsqu'on est connecté */}
                {isLoggedIn &&  <NavLink
                  className={({isActive}) => (isActive ? classes.active : "")}
                   to="/publish"
                  > 
                   <li>Graffer</li>
                 </NavLink>}

              
                {/**lien accueil lorsqu'on est connecté */}
                {isLoggedIn && conditionLinkActive && <NavLink
                  className={({isActive}) => (isActive ? classes.active : "")}
                  to="/"> 
                  <li>Flux</li>
                </NavLink>}


               {/**lien accueil à la création de sa fiche */}
                {isLoggedIn && !conditionLinkActive && <li onClick={errorModalHandler}>Flux</li>}

            
             
               {/**Lien Compte */}
               { 
                isLoggedIn &&
                  <li className={classes.page } 
                     onClick={navigateToAccount}>
                    Ma page
                  </li>
                 }
            


             {/**lien fiche utilisateur */}
              { 
                isLoggedIn &&
                <NavLink 
                  className={({isActive}) => (isActive ? classes.active : "")}
                   to="user"
                  > 
                  <li>Profil</li>
                </NavLink>
              
              }
                 
                 
                  {/**lien pour se déconnecter */}
              {  
                  isLoggedIn &&
                  <li onClick={logoutHandler} 
                      className={classes.disconnect}>
                        <LogoutIcon  fontSize='small' />
                  </li> 
              }
                 
             </ul>
           </nav>

           {/**Modale d'erreur**/}
           {error && 
           <ErrorModal
            title={error.title}
            message={error.message}
            onConfirm={() => setError(false)}
            />}
        </header>
        </div>
    )
}


export default MainHeader;