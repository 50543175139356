import React, { useEffect, useState } from 'react';
//import classes from "../Feed/FeedDisplayPost.module.css";
import classes from "./AllPostsOfOneUser.module.css";
import Card from "../UI/Card";
import FeedPhotoUser from '../Feed/FeedPhotoUser';
import FeedIdentifierCreator from '../Feed/FeedIdentifierCreator';
import FeedButton from '../Feed/FeedButton';
import FeedPosts from '../Feed/FeedPosts';
import FeedImagePost from '../Feed/FeedImagePost';
import FeedPostVideo from '../Feed/FeedPostVideo';
import FeedNewComment from '../Feed/Comments/FeedNewComment';
import FeedDisplayComment from '../Feed/Comments/FeedDisplayComment';
import { Link, useParams } from 'react-router-dom';
import FeedLike from '../Feed/FeedLike';
import useHttp from '../../hooks/use-http';
import { useSelector } from 'react-redux'; 

const AllPostsOfOneUser = ({onUpdate}) => {

 
    const authentification = useSelector((state) => state.authentification.dataResponse);
 
    const { id } = useParams();
 
    const [mounted, setMounted] = useState(true);

 
    const [messages, setMessages] = useState(null);
 
    const [updateDeletePost, setUpdateDeletePost] = useState(null);

    const [isUpdatingPost, setIsUpdatingPost] = useState(null);

    const [buttonSend, setButtonSend] = useState(false);

    const [isUpdatingPostFinish, setIsUpdatingPostFinish] = useState(false);

    
   
    const [idCommentButton, setIdCommentButton] = useState(null);

    const [isDisplayedComment, setIsDisplayedComment] = useState(false);

   
   
    //CUSTOM HOOK HTTP GET
    const {sendRequest: fetchGetPostsHandler} = useHttp();



    //pour mettre à jour un post qui est dans le feed
    const updatePostHandler = (event) => {
           
      setIsUpdatingPost({
         isUpdating: true,
         postToEdit: Number(event.target.id),
        
      });
    };

     //le message à récupérer et à envoyer sur la base de données
    //à cliqué sur le bouton envoyer
    const messageToSend = () => {
       
      setButtonSend(true);

    };



    //à cliqué sur le bouton commentaires du post
    const commentsDisplayPost = (event) => {
      
      //récupération de l'id sur le bouton commentaires
      setIdCommentButton(Number(event.target.id));

      //au clique sur le bouton commentaires je passe false à true ou bien l'inverse(toggle)
      setIsDisplayedComment((prevState) => !prevState);

    };



    //le message a bien été envoyé à la base de données
    const onUpdatePostFinish = () => {
       setIsUpdatingPost(null);
       setButtonSend(false);
       setIsUpdatingPostFinish((prevState) => !prevState);
       console.log("bon")
    }; 



    
 //pour aller chercher les posts sur la base de données
 useEffect(() =>  {
  //objet de configuration de la requête du custom hook
   const requestConfig =  {
        url: `${process.env.REACT_APP_GRAFFITI_API_URL}/api/posts/${id}?userId=${authentification.userId}`,
        method: "GET",
        headers: {   
           Authorization: `Bearer ${authentification.token}`,
        },
   }
  
   
   if(mounted) {
     

        id && authentification.userId && fetchGetPostsHandler(requestConfig, (applyData) => {
            setMessages(applyData);
         
 
        }) ;  
      

   }

   //fonction de nettoyage
  return () => {
     setMounted(true);
    };    
    
 },[onUpdate, 
    updateDeletePost,
    isUpdatingPostFinish, 
    authentification.userId, 
    fetchGetPostsHandler,
    mounted, 
    authentification.token,
    id]);



      

   //mettre le dernier message envoyé en haut de la pile
   //le dernier message envoyé est le premier à être affiché
   const orderDisplayMessage = 
       messages && messages.sort((a, b) => {
         return(
          b.id_posts_user - a.id_posts_user
         )
  
       });
   



  return (
    <section className={classes.allPostsOfOneUser}>
       
        <h2>Graffs</h2> 
       
        <div className={classes.container}> 
        
            { orderDisplayMessage && 
              orderDisplayMessage.map((message) =>  (
             <section key={message.id_posts_user}> 
                <Card 
                  className={classes.card} 
                    >


                     <Link
                        //to={`/fiche_utilisateur/read/${message.fiche_user_userId}`} 
                        to={`/user/read/${message.fiche_user_userId}`} 

                        >
                      <FeedIdentifierCreator 
                         user_prenom={message.fiche_user_prenom}
                         posts_date={message.posts_user_date}
                        />
                     </Link>
                  

                  <div className={classes.photoMessage}>
                     <Link
                        //to={`/fiche_utilisateur/read/${message.fiche_user_userId}`}
                        to={`/user/read/${message.fiche_user_userId}`} 

                       >
                        <FeedPhotoUser 
                          photoProfilUrl={message.fiche_user_photoProfilUrl}
                         />
                     </Link>



                       <FeedPosts
                          message={message.posts_user_message}
                          isUpdatingPost={isUpdatingPost}
                          idPostsUser={message.id_posts_user}
                          buttonSend={buttonSend}
                          userIdPost={message.posts_user_userId}
                          token={authentification.token}   
                          onUpdatePostFinish={onUpdatePostFinish}
                            />
                  
                    </div>

                    
                    <FeedImagePost 
                      photoPost={message.posts_user_photoUrlLink} />

                    <FeedPostVideo 
                      videoPost={message.posts_user_videoUrlLink} />

                  
                  <div className={classes.likeAndButton}> 
                    
                    <FeedLike  
                      token={authentification.token}
                      idPostsUser={message.id_posts_user}
                      userIdToken={Number(authentification.userId)}
                      />


                   <FeedButton 
                     userIdToken={Number(authentification.userId)}
                     userIdPost={message.posts_user_userId} 
                     idPostsUser={message.id_posts_user}
                     token={authentification.token}
                     onUpdateDelete={(idPostsUser) => setUpdateDeletePost(idPostsUser)}
                     onModificationMessage={updatePostHandler}
                     isUpdatingPost={isUpdatingPost}
                     onSendMessage={messageToSend}
                     onCommentsDisplayPost={commentsDisplayPost}
                      
                     />
                
                 </div>

                </Card>
             

                {isDisplayedComment && 
                 message.id_posts_user ===
                 idCommentButton && (
                 <> 

                    <FeedDisplayComment
                      onUpdate={onUpdate}
                      token={authentification.token} 
                      userIdToken={Number(authentification.userId)} 
                      idPostsUser={message.id_posts_user}
                      idCommentButton={idCommentButton}  
                      isDisplayedComment={isDisplayedComment}   
                     />


                    <FeedNewComment
                      idPostsUser={message.id_posts_user}
                         />
                    
                 </>
                    
                    )}
               </section>
             ))}

         

        </div>
      
    </section>
  )
}

export default AllPostsOfOneUser;
